import React from 'react'
import styled from 'styled-components'

import {Link} from 'gatsby'
import {colors, font, fontfamily} from '../styles/variables'

const Wrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 3.2rem 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  & > *{
    flex: 33% 0 0;
    text-align: center
  }
`

const Pagenum = styled.span`
  display: block;
  font-size: 1.6rem;
`

const StyledLink = styled(props => <Link {...props} />)`
  font-size: 1.6rem;
  text-align: center;
  display: block;
  color: ${colors.blue};
`

const Pagination = props => {
  const {numPages, currentPage,slug} = props
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
  currentPage - 1 === 1
    ? `/${slug}/archive/`
    : `/${slug}/archive/` + (currentPage - 1).toString() + '/'
  const nextPage = `/${slug}/archive/` + (currentPage + 1).toString() + '/'

  return (
    <Wrapper>
      <div>
        {!isFirst && (
          <StyledLink to={prevPage} rel='prev'>
          ← 前へ
          </StyledLink>
        )}
      </div>
      <Pagenum>{props.currentPage} / {props.numPages}</Pagenum>
      <div>
        {!isLast && (
          <StyledLink to={nextPage} rel='next'>
          次へ →
          </StyledLink>
        )}
      </div>
    </Wrapper>
  )
}
export default Pagination
